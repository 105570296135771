import React from "react"
import { Link } from "gatsby"
import "./header.scss"

export const Header = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <h1 className="header__heading">
          <Link to="/">
            crafting<span>{"<codes/>"}</span>
          </Link>
        </h1>
        <nav className="header__nav">
          <Link to="/">About</Link>
        </nav>
      </div>
    </header>
  )
}
