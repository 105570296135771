import React from "react"
import "./footer.scss"

export const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer__newsletter">
        <div className="footer__wrapper">
          <h2>Subscribe to our newsletter!</h2>
          <p>Weekly news</p>
        </div>
      </section>

      <section className="footer__menu">
        <div className="footer__wrapper">
          © {new Date().getFullYear()} crafting.codes
        </div>
      </section>
    </footer>
  )
}
