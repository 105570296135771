import * as React from "react"
import { Header } from "../header/header"
import { Footer } from "../footer/footer"
import "./layout.scss"

const Layout = ({ location, title, children }) => {
  return (
    <>
      <div className="layout__content">
        <Header />
        <main className="layout__wrapper">
          <article>{children}</article>
          {/* <aside>
            <h2>Aside</h2>
          </aside> */}
        </main>
      </div>
      <Footer />
    </>
  )
}

export default Layout
